var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dp-capsule" },
    [
      _vm.isLoading
        ? _c("DpPageLoading", { attrs: { "is-loading": _vm.isLoading } })
        : _vm._e(),
      _c(
        "div",
        { style: { opacity: !_vm.isLoading ? 1 : 0 } },
        [
          _c("p", { staticClass: "dp-capsule-lead" }, [
            _vm._v(_vm._s(_vm.WORDS.DRIP_POD_CAPSULE.LEAD)),
          ]),
          _vm.hasRecentContents || _vm.hasRecommendContents
            ? _c(
                "div",
                { staticClass: "dp-capsule-head" },
                [
                  _vm.hasRecentContents
                    ? _c(
                        "DpSection",
                        {
                          attrs: {
                            "title-en-size": "lg",
                            "title-en": _vm.WORDS.DRIP_POD_CAPSULE.RECENT.EN,
                            "title-jp": _vm.WORDS.DRIP_POD_CAPSULE.RECENT.JP,
                          },
                        },
                        [
                          _c("DpCapsuleList", {
                            attrs: { contents: _vm.recentContents },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.hasRecommendContents
                    ? _c(
                        "DpSection",
                        {
                          attrs: {
                            "title-en-size": "lg",
                            "title-en": _vm.WORDS.DRIP_POD_CAPSULE.RECOMMEND.EN,
                            "title-jp": _vm.WORDS.DRIP_POD_CAPSULE.RECOMMEND.JP,
                          },
                        },
                        [
                          _c("DpCapsuleList", {
                            attrs: { contents: _vm.recommendContents },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.capsuleContents
            ? _c(
                "div",
                { staticClass: "dp-capsule-taste" },
                [
                  _c(
                    "DpSection",
                    {
                      attrs: {
                        "title-en-size": "lg",
                        "title-en": _vm.WORDS.DRIP_POD_CAPSULE.TASTE.EN,
                        "title-jp": _vm.WORDS.DRIP_POD_CAPSULE.TASTE.JP,
                      },
                    },
                    [
                      _vm._l(
                        _vm.capsuleContents,
                        function (capsuleContent, key, index) {
                          return [
                            capsuleContent.length
                              ? _c(
                                  "DpSection",
                                  {
                                    key: index,
                                    attrs: {
                                      "title-en": _vm.getTasteTitleEn(key),
                                      taste: key,
                                    },
                                  },
                                  [
                                    _c("DpCapsuleList", {
                                      attrs: { contents: capsuleContent },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        }
                      ),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("DpCapsuleDetail"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }