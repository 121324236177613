import { useDate } from '@/composables/useDate';
const { formatDateType, isInThePeriod, isAfterNow } = useDate();

export const usePublishable = () => {
  /**
   * ステータスが「公開」かつ、公開期間内のコンテンツを取得する
   * @param {object} content
   */
  const isPublishPeriod = (content) => {
    // コンテンツが存在しない・非公開の設定であればfalse
    if (!content || !content.is_publishable) return false;

    if (
      // 公開期間の指定がない場合は公開期間内とみなす
      /* eslint-disable no-prototype-builtins */
      !!content.is_publishable &&
      (!content.hasOwnProperty('publish_start_at') ||
        !content.hasOwnProperty('publish_end_at'))
      /* eslint-enable */
    )
      return true;

    const start = formatDateType(content.publish_start_at);
    const end =
      content.publish_end_at !== null
        ? formatDateType(content.publish_end_at)
        : null;

    // 終了期限なしの場合は開始日時で期間内かを判定
    if (end === null) return isAfterNow(start);
    else return isInThePeriod(start, end);
  };

  /**
   * ステータスが「公開」かつ、公開期間内のコンテンツであれば取得する
   * @param {object} content
   */
  const getPublishableContent = (content) => {
    if (!content?.is_publishable || !isPublishPeriod(content)) return;
    return content;
  };

  /**
   * ステータスが「公開」かつ、公開期間内のコンテンツの配列を取得する
   * @param {array} contents
   */
  const getPublishableContents = (contents) =>
    contents.filter((content) => getPublishableContent(content));

  /**
   * ステータスが「一覧で公開」「公開」かつ、公開期間内のコンテンツであれば取得する
   * @param {object} content
   */
  const getListPublishableContent = (content) =>
    !!content?.is_list_publishable && isPublishPeriod(content);

  /**
   * ステータスが「一覧で公開」「公開」かつ、公開期間内のコンテンツの配列を取得する
   * @param {array} contents
   */
  const getListPublishableContents = (contents) =>
    contents.filter((content) => getListPublishableContent(content));

  return {
    isPublishPeriod,
    getPublishableContent,
    getPublishableContents,
    getListPublishableContents
  };
};
